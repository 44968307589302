import * as React from "react"
import { Layout } from "../components/Layout";
import { graphql } from 'gatsby';
import { PageTitle } from "../components/PageTitle";
import { ThumbnailLinkGrid } from "../components/ThumbnailLinkGrid";
import { getImage } from "gatsby-plugin-image";

export const Head = () => 
  <>
    <title>Daymarks of the world | Worldwide Lighthouses</title>
    <meta name="description" content="All of the daymarks which the worldwide lighthouses project has on record" />
  </>;

const DaymarksPage = ({ data }) => {
  const daymarks = data.daymarks.edges.map(edge => edge.node);
  const images = data.images.edges;

  return (
    <Layout>
      <PageTitle title="Daymarks" />
      <ThumbnailLinkGrid links={daymarks.map(daymark => { 
        const urlEncodedName = encodeURIComponent(daymark.frontmatter.name.toLowerCase().replaceAll(" ", "-"));
        const imageEdge = images.find(image => image.node.relativeDirectory === urlEncodedName);
        const image = imageEdge ? getImage(imageEdge.node) : null;

        return { 
          link: `/daymarks/${urlEncodedName}`, 
          title: daymark.frontmatter.name,
          image
        };
      })} />
    </Layout>
  )
}

export const Query = graphql`
  query DaymarksQuery {
    daymarks: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/content/daymarks/.*.md$/"}}
      sort: { order: ASC, fields: frontmatter___name }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          id
        }
      }
    }
    images: allFile(
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }
      sort: { order:ASC fields: absolutePath }
    ) {
      edges {
        node {
          relativeDirectory,
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 350
              height: 250
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default DaymarksPage;